<template>
  <div class="h-100" style="overflow-y: auto">
    <div class="container">
      <div class="mt-5">
        <span v-for="(section,index) in faqs" :key="index">
          <button
            type="button"
            class="btn btn-teal"
            :class="{ 'active': isActive(index) }"
            @click="navigate(index)"
          >{{section.name}}</button>
        </span>
      </div>

      <div
        class="mt-5 text-uppercase h3 ml-3 pb-3"
        style="letter-spacing:2.5px; color: #B1B1B1;"
      >{{faqs[currentTab].name}} questions</div>

      <div class="mt-3">
        <div v-for="(entry,index) of faqs[currentTab].content" :key="index">
          <div class="mt-3 ml-3">
            <font-awesome-icon icon="circle" :color="entry.collapsed ? '#DBDBDB' : '#1095aa'" style="font-size: 20px;"/>

            <a
              class="ml-3"
              style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 16px;letter-spacing: -0.5px;color: #333333; padding-left: 10px;"
              data-toggle="collapse"
              :href="'#' + currentTab + index"
              role="button"
              aria-expanded="false"
              :aria-controls="'' + currentTab + index"
              @click="changeColor(index)"
            >{{entry.question}}</a>
          </div>
          <div :class="{collapse: entry.collapsed }" :id="'' + currentTab + index">
            <b-table striped hover v-if="entry.answer && entry.answer.table" :items="entry.answer.table"></b-table>
            <div
              v-else-if="entry.answer"
              style="margin-left: 64px; font-family: Roboto;font-style: normal; font-weight: normal;font-size: 14px; color: #747578; padding-top: 10px;"
              v-html="entry.answer"
            ></div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <h3>Got another question?</h3>
        <p>Send us an email! <a href="mailto:support@guard.gg?subject=Help!" style="text-decoration: underlined">support@guard.gg</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let faqs = [
      {
        name: "Buying",
        content: [
          {
            question: "Can I get a refund for a purchase I made?",
            answer: "We do not offer refunds on skin purchases. Unused account balance can be refunded if the entire initial deposit amount was not used. To request a refund, please send us an email.",
            collapsed: true
          }
        ]
      },
      {
        name: "Selling",
        content: [
          {
            question: "What are the sales fees?",
            answer: "The sales fee is 8% for all items below $30. Items over $30 have a 6% sales fee.",
            collapsed: true
          },
          {
            question: "How do I de-list my item for sale?",
            answer: "Navigate to the “My listings” page and find the item you wish to unlist. Click “remove”, and the item will be sent to your on-site inventory. From there, it can be re-listed or withdrawn.",
            collapsed: true
          }
        ]
      },
      {
        name: "Deposit",
        content: [
          {
            question: "How do I deposit funds via crypto?",
            answer: `You can add funds by hovering over your profile on the top right of the header and selecting “deposit” on the dropdown.`,
            collapsed: true
          },
          {
            question: "What credit cards do you accept?",
            answer: `We accept all major credit card brands including Visa, Mastercard, American Express, and Discover.`,
            collapsed: true
          },
          {
            question: "How do I purchase with credit cards?",
            answer: `You can pay via credit card on the checkout page. Once you have put your desired items in your cart, hover over the cart icon, and click “checkout”. Any site funds you currently have will be automatically applied to your purchase. Any remaining balance can be paid via credit card. Click “Confirm and Pay”, and you will be redirected to our Stripe checkout flow. Please note Guard.gg does not store any of your credit card information, it is all handled by our payment processor.`,
            collapsed: true
          },  
          {
            question: "How do I deposit items?",
            answer: `You can deposit items by clicking the “sell” button located on the top right of the site header.`,
            collapsed: true
          },
          {
            question: "I deposit funds, but they aren’t in my Guard.gg balance!",
            answer: `Some funding methods may take longer to show up than others. Although most deposits will take less than 1 hour, please allow 24 hours for crypto deposits to reflect in your balance. If your balance is still not updated, please contact support for more assistance.`,
            collapsed: true
          },
          {
            question: "What are the fees for each fund deposit method?",
            collapsed: true,
            answer: {
              table: [
                { "paymentType": "Crypto", fee: "Free transfer from Coinbase accounts. Depositor pays the miner fee."},
                { "paymentType": "Credit Card", fee: "2.9% + .30¢*"},
              ]
            }
          }
        ]
      },
      {
        name: "Withdraw", 
        content: [
          // {
          //   question: "Why can’t I withdraw my CS:GO item?",
          //   answer: "CS:GO are locked by a seven day trade hold whenever they are traded to a new account. This limit is set by Valve and we cannot modify or expedite it. Even though the item won’t be available to withdraw immediately, the seller still receives a credit to their account and the item is transferred to the buyer’s Guard.gg site inventory.",
          //   collapsed: true
          // },
          {
            question: "Why am I not getting a trade offer?",
            answer: "Occasionally, Steam goes down for routine maintenance and we are unable to process trade requests during that time. Please request your trade offer when Steam services return.",
            collapsed: true
          },
          {
            question: "What Crypto wallet should I use?",
            answer: `We recommend using Coinbase for free transfers. You can sign up for a free wallet <a href="https://coinbase.com/join/llc_83o">here</a>`,
            collapsed: true
          }
        ]
      },
    ]

    let allFaqs = [];
    for (let section of faqs) {
      allFaqs = allFaqs.concat(section.content);
    }
    faqs.unshift({
      name: "All FAQs",
      content: allFaqs
    });

    return {
      currentTab: 0,
      faqs,
    };
  },
  components: {},
  methods: {
    isActive(index) {
      return this.currentTab == index;
    },
    navigate(index) {
      this.currentTab = index;
    },
    changeColor(index) {
      if(this.faqs[this.currentTab].content[index].collapsed == true)
        this.faqs[this.currentTab].content[index].collapsed = false
      else
        this.faqs[this.currentTab].content[index].collapsed = true
    }
  },
  computed: {
    getIconColor: function() {
      return "#ED6B33";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/brand.scss";

.big {
  font-size: 25px;
  word-spacing: 5px;

  margin: 0px 15px 0px 15px;
}

.active {
  background-color: #091629 !important;
  font-weight: bold !important;
  border: 0px !important;
  color: #ffffff !important;
}

.btn {
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  margin-right: 20px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: white;
  //width: 90px;
}
.btn:hover{
  background-color: #ffffff;
  color: #1D2021;
  border: 1px solid #4b4b4b;
}
.btn:hover:active{
  background-color: #091629;
  color: white;
}
</style>